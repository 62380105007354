<template>
    <div class="card-number-list">

      <div class="row" v-for="(opt,i) in itemOptions" :key="'phonenummer_item_'+i+opt.productid">
        <div class="col-6 col-sm-8 pr-0 mb-1 text-left d-flex align-items-center">
          <span class="mr-1 mr-md-3">
            <i class="fal fa-times hand card-delete-btn" @click="removeProduct(i)" />
          </span>
          <div v-if="opt.isBlock">
            <span class="d-md-inline d-block">0{{ opt.formatNumber }}</span> 
            <span class="d-md-inline d-block mx-0 mx-md-2">t/m</span> 
            <span class="d-md-inline d-block">{{ opt.formatEndNumber}}</span>
          </div>
          <span v-else>0{{ opt.formatNumber }}</span>
        </div>
        <div class="col-3 col-sm-2 pr-0 d-flex align-items-center justify-content-end"><span class="text-nowrap">{{ opt.setupPrice|price }}</span></div>
        <div class="col-3 col-sm-2 d-flex align-items-center justify-content-end"><span class="text-nowrap">{{ opt.price|price }}</span></div>
      </div>

    </div>
</template>

<script>
    import { Abstract_wilkelmand} from "../../mixins/Abstract_wilkelmand";
    import BasketItemHeader from "./BasketItemHeader";
    export default {
        name: "TransferNumberBasketList",
        mixins:[Abstract_wilkelmand],
        components:{ BasketItemHeader },
        props:{
            viewHeader:{
                type: Boolean,
                required:false,
                default:true
            }
        },
        data(){
          return {
            itemOptions:[]
          }
        },
        computed:{
          // numberPrice(){
          //   return this.$root.transferNumbersConf.price;
          // },
          // numberSetupPrice(){
          //   return this.$root.transferNumbersConf.setupPrice;
          // },
          basketTransferNumbers(){
            if(Object.prototype.hasOwnProperty.call(this.$root.basket, 'transferNumbers')){
              return this.$root.basket['phoneNumbers'];
            }
          },
          // itemOptions(){
          //   if(Object.prototype.hasOwnProperty.call(this.$root.basket, 'transferNumbers')){
          //     return this.$root.basket['transferNumbers'].options;
          //   }
          //   return {};
          // }
        },
        methods:{
            removeProduct(key){
                this.$root.forNumberRequest(this.basketItem.options[key], 'remove', 'transfer');
                this.basketItem.options = this.basketItem.options.filter(function (val, i) {
                    return i !== key;
                });

                if(this.basketItem.options.length < 1){
                    delete this.$root.basket['transferNumbers'];
                }

                this.calculateTransferNumber(this.basketItem, true);
                this.$root.appEvents.emit('basket-re-calculate');
                this.$root.appEvents.emit('change_basket', this.basket);
            },
            lastCookieChanged(manuel){
                if(manuel !== true){
                    this.calculateNewNumber(this.basketItem, true);
                }
            },
            allCountProps(){
                return this.basketItem.options.length;
            }
        },
        async mounted() {
            if(this.viewHeader === false){
              this.collapse = true;
            }
            await this.calculateTransferNumber(this.basketItem);
            this.$root.appEvents.on('transferNumber-re-calculate', this.lastCookieChanged);

            if(Object.prototype.hasOwnProperty.call(this.$root.basket, 'transferNumbers')){
              for(let [k,n] of Object.entries(this.$root.basket['transferNumbers'].options) ){
                let numberProduct = await this.$root.numberProductData(n.areacode, true, (n.blockSize ?? 1));
                
                let isBlock = false; 
                let blockLastNum = 0;
                
                if(!Object.prototype.hasOwnProperty.call(n,"formatNumber")){
                    if(Object.prototype.hasOwnProperty.call(n,"phoneformat")){
                      n.formatNumber = n.phoneformat;
                    }
                    if(Object.prototype.hasOwnProperty.call(n,"number_start_full")){
                      n.formatNumber = n.number_start_full;
                    }
                }

                if(parseInt(n.blockSize, 10) > 1){
                  isBlock = true;
                  blockLastNum = n.blockSize - 1;
                }

                this.itemOptions.push(
                    Object.assign(n, {
                      price:numberProduct.price,
                      setupPrice: numberProduct.setupPrice,
                      isBlock,
                      blockLastNum
                    })
                );
              }

            }else{
              this.itemOptions = [];
            }

        },
        destroyed() {
            this.$root.appEvents.removeListener('transferNumber-re-calculate', this.lastCookieChanged);
        }
    }
</script>

<style scoped>

</style>

<template>
    <div class="package-page">
        <Package_select v-if="$root.packageLoad" />
    </div>
</template>
<script>
    import Package_select from "../components/Package_select";
    export default {
        name: 'Pakketten',
        components:{Package_select}
    }
</script>
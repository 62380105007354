<template>
    <div id="nieuw_nummer" class="funnel-container">
        <div class="map-box2 pt-4">
            <div class="map-box-header2 pt-4 pb-3 px-5 d-flex align-items-start">
                <span class="badge badge-primary mr-3 mt-1 py-1 px-2">2</span>
                <div class="d-flex align-items-center flex-wrap">
                    <span class="font-weight-bold">Kies een mooi <span class="text-nowrap">nummer -</span> </span>
                    <span class="ml-1">Slechts {{ numberPrice|price }} p.m. *</span>
                </div>
            </div>
            <div class="px-5 position-relative">
                <p class="theme-text-dark">
                    Kies regio (netnummer)
                </p>
                <div class="row">
                    <div class="col-md-6">
                        <div class="vue-select mb-4 mb-md-0">
                            <vSelect :options="araCodeOpt" v-model="selectedAreCode" :reduce="label => label.code">
                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching">
                                        Helaas, deze regio is nit gevonden. <!--<em>{{ search }}</em>-->
                                    </template>
                                    <em style="opacity: 0.5;" v-else>Start typing to search for a country.</em>
                                </template>
                            </vSelect>
                        </div>
                    </div>
                </div>


                <div class="row mb-3">
                    <div class="col-md-7"></div>
                    <div class="col-md-5 text-md-right">
                        <a class="font-weight-bold theme-text-blue" @click="nextNummers()">
                            <i class="fas fa-sync mr-2" :class="{'fa-spin':sendRequest}"/>
                            <span>Toon meer nummers</span>
                        </a>
                    </div>
                </div>

                <loading :addCss="'abslt'" v-if="!readyPhoneNumber"/>
                <div class="new-number-area" v-else>
                    <Loading :addCss="'abslt'" v-if="sendRequest"/>
                    <div class="new-number-list" v-else>
                        <div class="row">
                            <div class="col-md-6" v-for="(opt, k) in numberList" :key="k">
                                <div class="select-number d-flex align-items-center" :class="numberItemCss(opt)"
                                     @click="numberAction(k)">
                                    <div class="number_icon">
                                        <div class="available-num" v-if="!isAdded(opt.areacode+opt.phone)">
                                            <i class="fal plus-icon fa-plus view-normal"></i>
                                            <i class="fal plus-icon fa-plus view-hover"></i>
                                        </div>
                                        <div class="selected-num" v-else>
                                            <i class="fal fa-check view-normal"></i>
                                            <i class="fas fa-trash-alt view-hover"></i>
                                        </div>
                                    </div>
                                    <div class="number-no">
                                        {{ opt.phoneformat }}
                                    </div>
                                    <i class="fas fa-spinner fa-pulse ml-4" v-if="opt.xhrRequest"></i>
                                    <div class="number-act ml-auto font-weight-bold">
                                        {{ numberPrice|price }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 text-center p-4" v-if="numberList.length < 1">
                                <em>nummer niet gevonden</em>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!--
        <div class="map-box-external-footer mt-5 d-flex align-items-center flex-column flex-sm-row">
          <a @click="$root.gotoNextPage('nieuw_nummer', 1)" class="theme-text-blue">
            <i class="far fa-plus"/>
            Bestaande nummer(s) meeverhuizen
          </a>
        </div>
        -->
    </div>
</template>

<script>
import Loading from "./Loading";
import vSelect from 'vue-select'

export default {
    name: "Nieuw_nummer",
    props: {
        defaultAreaCode: {
            type: [Number, String],
            default: 0,
        }
    },
    components: {Loading, vSelect},
    data() {
        return {
            selectedAreCode: '85',
            selectAreaInfo: {},
            page: 1,
            countrycode: 31,
            limit: 8,
            numberList: {},
            addedNumber: {},
            sendRequest: false,
            readyPhoneNumber: false
        }
    },
    computed: {
        areaCodes: {
            set: function (val) {
                this.$root.areaCodes = val;
            },
            get: function () {
                return this.$root.areaCodes;
            }
        },
        araCodeOpt() {
            let that = this;
            let opt = [];
            Object.values(this.areaCodes).forEach(function (val) {
                if (that.isActiveAreCode(val)) {
                    opt.push({
                        label: '0' + val.areacode + ' ' + val.name,
                        code: val.areacode
                    });
                }
            });
            return opt;
        },
        buttonText() {
            return Object.keys(this.addedNumber).length > 0 ? 'Ga verder' : 'Overslaan';
        },
        numberPrice() {
            try{
                return this.selectAreaInfo.price;
            }catch (e) {
                return this.$root.newNumberConf.price;
            }

        },
        numberSetupPrice() {
            try{
                return this.selectAreaInfo.setupPrice;
            }
            catch (e) {
                return this.$root.newNumberConf.setupPrice;
            }
        }
    },
    watch: {
        selectedAreCode: async function (val, oldVal) {
            this.page = 0;
            this.getPhoneNumbers().catch(e => e);
        },
        addedNumber: {
            handler: function (newVal, oldVal) {
                this.optSynchronous();
            },
            deep: true
        }
    },
    methods: {
        nextNummers() {
            this.page += 1;
            this.getPhoneNumbers();
        },
        numberItemCss(number) {
            let cssObj = {};
            cssObj.selected = false;
            cssObj.not_selected = true;
            if (this.isAdded(number.areacode + "" + number.phone)) {
                cssObj.selected = true;
                cssObj.not_selected = false;
            }
            return cssObj;
        },
        isActiveAreCode(opt) {
            if (opt.onlytransfer === true) {
                return false;
            }
            if (opt.register !== true) {
                return false;
            }
            return true;
        },

        isAdded(k) {
            return this.addedNumber.hasOwnProperty(k);
        },

        numberAction(k) {
            try {
                let number = this.numberList[k];
                let key = number.areacode + "" + number.phone;

                if (number.xhrRequest === true) {
                    return;
                }
                number.xhrRequest = true
                if (this.isAdded(key)) {
                    this.removeNumber(k, number);
                } else {
                    this.addNumber(k, number);
                }
                this.addedNumber = Object.assign({}, this.addedNumber);
            } catch (e) {
            }
        },

        addNumber(k, v) {
            let that = this;
            let key = v.areacode + "" + v.phone;
            that.addedNumber[key] = v;
            this.forNumberRequest(k).then(function (http) {
                let res = http.giveAsObject();
                if (parseInt(res.code) !== 200) {
                    that.$myAlert({
                        title: '',
                        text: res.message
                    });
                    delete that.addedNumber[key];
                    delete that.numberList[k];
                    that.optSynchronous();
                    that.numberList = Object.assign({}, that.numberList);
                } else {
                    that.numberList[k].xhrRequest = false
                }
            });
        },
        removeNumber(k, v) {
            let that = this;
            let key = v.areacode + "" + v.phone;
            delete this.addedNumber[key];
            this.forNumberRequest(k, 'remove').then(function (res) {
                //console.log(res);
                that.numberList[k].xhrRequest = false
            });
        },
        getAreaCode() {
            let that = this;
            if (Object.keys(this.$root.areaCodes) < 1) {
                that.$root.getAreaCode(that.getPhoneNumbers).then(async () => {
                    this.selectAreaInfo = await this.$root.numberProductData(this.selectedAreCode, false);
                });
            } else {
                that.getPhoneNumbers();
            }
        },
        forNumberRequest(k, process = 'add') {
            let that = this;
            return this.$root.sendRequest({
                handler: 'onNumberAction',
                id: this.numberList[k].id,
                process,
                number_start: this.numberList[k].phone,
                number_end: '',
                number_start_full: this.numberList[k].phoneformat,
                type: 'new',
                areacode: this.numberList[k].areacode
            }, function () {

            });
        },
        async getPhoneNumbers() {
            let that = this;
            that.sendRequest = true;
            return this.$root.sendRequest({
                handler: 'onGetNumberList',
                page: that.page,
                countrycode: that.countrycode,
                areacode: that.selectedAreCode,
                limit: that.limit
            }, async function (http, response) {
                let data = http.giveAsObject();
                if (parseInt(data.code, 10) === 200) {
                    let numbers = data.data;
                    let newData = {};

                    for (let [k, n] of Object.entries(numbers)) {
                        let numberProduct = await that.$root.numberProductData(n.areacode, false);
                        newData[k] = Object.assign(n, {
                            xhrRequest: false,
                            productid: numberProduct.productid
                        });
                    }

                    that.numberList = newData;
                    Object.entries(that.numberList).forEach(function ([k, v]) {
                        if (v.hasOwnProperty('selected')) {
                            if (v.selected) {
                                that.addNumber(k, v);
                            }
                        }
                    });
                } else {
                    that.numberList = [];
                }
                that.readyPhoneNumber = true;
                that.sendRequest = false;
                that.selectAreaInfo = await that.$root.numberProductData(that.selectedAreCode, false);
            }, async function (err) {
                that.readyPhoneNumber = true;
                that.sendRequest = false;
                that.selectAreaInfo = await that.$root.numberProductData(that.selectedAreCode, false);
            });
        },
        optSynchronous() {

            if (!this.$root.basket.hasOwnProperty('phoneNumbers')) {
                this.$root.basket['phoneNumbers'] = {};
            }

            this.$root.basket['phoneNumbers'] = Object.assign(
                this.$root.basket['phoneNumbers'],
                {
                    formType: 'newNumber',
                    options: this.addedNumber
                }
            );
            this.$root.appEvents.emit('basket-re-calculate');
            this.$root.appEvents.emit('change_basket', this.basket);
        },
        goForward() {
            this.$root.gotoNextPage('nieuw_nummer', 0);
        },
        reloadNumberCookie() {
            let that = this;
            try {
                if (Object.keys(that.$root.basket['phoneNumbers'].options).length > 0) {
                    that.addedNumber = that.$root.basket['phoneNumbers'].options;
                }
            } catch (e) {
                setTimeout(function () {
                    try {
                        that.addedNumber = that.$root.basket['phoneNumbers'].options;
                    } catch (e) {
                    }
                }, 1500);
            }
        }
    },
    mounted() {
        let that = this;
        this.reloadNumberCookie();

        if (parseInt(this.defaultAreaCode) !== 0) {
            this.selectedAreCode = this.defaultAreaCode;
        }
        this.getAreaCode();
        this.$root.appEvents.on('next' + this.$route.params.sub, this.goForward);
        this.$root.appEvents.on('cookie-product-loaded', this.reloadNumberCookie);
        this.$root.appEvents.on('remove-phone-number', this.numberAction);

        this.$root.appEvents.on("cookie-product-loaded", function () {
            if (that.$route.params.sub === "nieuw_nummer") {
                that.$root.gotoPage('telefoonummers');
            }
        });
        this.$root.appEvents.emit("page.step", {step: 2});
    },
    destroyed() {
        this.$root.appEvents.removeListener('next' + this.$route.params.sub, this.goForward);
        this.$root.appEvents.removeListener('cookie-product-loaded', this.reloadNumberCookie);
        this.$root.appEvents.removeListener('remove-phone-number', this.numberAction);
    }
}
</script>

<style lang="scss">
.new-number-area {
    position: relative;
    min-height: 190px;
    margin-bottom: 30px;

    .select-number {
        background: #FFFFFF;
        border: 1px solid #E5E5E5;
        box-sizing: border-box;
        border-radius: 4px;
        height: 46px;
        width: 100%;
        margin-bottom: 15px;
        padding: 0 11px;
        color: #002d47;
        cursor: pointer;

        .number-no {
            font-weight: 500;
        }

        .plus-icon{
            width: 26px;
            height: 26px;
            border-radius: 50%;
            text-align: center;
            line-height: 26px;
            margin-right: 10px;
        }
        .available-num{
            .view-normal{
                color: #82B636;
                background-color: #F9F9F9;
            }
            .view-hover{
                color: #FFFFFF;
                background-color: #82B636;
            }
        }
        .selected-num{
            i{
                width: 26px;
                height: 26px;
                text-align: center;
                line-height: 26px;
                margin-right: 10px;
            }
        }

        .view-normal {
            display: block;
        }

        .view-hover {
            display: none;
        }

        &:hover {
            background: rgba(130, 182, 54,.3);
            border: 1px solid #82B636;
            .view-normal {
                display: none;
            }

            .view-hover {
                display: block;
            }
        }

        &.selected {
            background: #82B636;
            color: #ffffff;
        }
    }

}

@media (max-width: 399px) {
    .vs__dropdown-toggle {
        //height: auto;
        height: calc(1.5em + 2rem + 2px) !important;
    }
}
</style>
import { render, staticRenderFns } from "./OnlySimBasketList.vue?vue&type=template&id=e09d1ac4&scoped=true"
import script from "./OnlySimBasketList.vue?vue&type=script&lang=js"
export * from "./OnlySimBasketList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e09d1ac4",
  null
  
)

export default component.exports
const Promotions = {
    data(){
        return {
            promotionData:{},
            annuallyPromotions:{},
            monthlyPromotions:{},
            promotionCodes:{}
        }
    },
    computed:{
        currentPromotion(){
            return this.$root.undertaking === 'annually' ? this.annuallyPromotions : this.monthlyPromotions;
        },
        currentPromotionCode(){
            try{
                return this.promotionCodes[this.$root.undertaking];
            }catch (e) {
                return [];
            }
        },
        annuallyFlexPromotionPercent(){
            let percent = 0;
            try{
                if(this.annuallyPromotions.products.includes(this.$root.flexProduct.id)){
                    if(parseInt(this.annuallyPromotions.type) < 1){
                        percent = this.annuallyPromotions.value;
                    }
                    if(Object.prototype.hasOwnProperty.call(this.annuallyPromotions, 'customValue')){
                        if(typeof this.annuallyPromotions.customValue[this.$root.flexProduct.id] !== "undefined"){
                            percent = this.annuallyPromotions.customValue[this.$root.flexProduct.id];
                        }
                    }

                    if(Object.prototype.hasOwnProperty.call(this.annuallyPromotions, 'customValueType')){
                        if(typeof this.annuallyPromotions.customValueType[this.$root.flexProduct.id] !== "undefined"){
                            if(parseInt(this.annuallyPromotions.customValueType[this.$root.flexProduct.id]) > 0){ //type not percent
                                percent = 0;
                            }
                        }
                    }

                }
            }catch (e) {}
            return percent;
        }
    },
    methods:{
        setPromotionsProductPrice(productType, productID, total, orderCount= null){
            let that = this;
            let calculate = false;
            let hasDiscount = false; 
            let returnTotal = total;
            let promotion = that.currentPromotion;

            if(typeof promotion === "undefined" || !promotion){
                return {hasDiscount, 'total':returnTotal, calculate};
            }
            
            let promotionValue = parseFloat(promotion.value); 
            let promotionType = parseInt(promotion.type);
            let isrecurring = parseInt(promotion.isrecurring);
            let recurringlimit = parseInt(promotion.recurringlimit);
            
            
            if(Object.prototype.hasOwnProperty.call(promotion.customValues, productID)){
                let customVals = promotion.customValues[productID];
                
                if(parseInt(customVals.apply_type, 10) > -1){
                    promotionValue = parseFloat(customVals.value);
                    promotionType = parseInt(customVals.apply_type);
                    isrecurring = parseInt(customVals.isrecurring);
                    recurringlimit = parseInt(customVals.recurringlimit);
                }
            }
            
            if(productType === "monthly"){
                calculate = true;
            }
            else{
                calculate = productType === "oneTime" && parseInt(promotion.isonetime) === 1;
            }

            try {
                if (!promotion.products.includes(productID)) {
                    calculate = false;
                }
            }catch (e) {
                calculate = false;
            }

            if(parseInt(promotion.dontapply_firstinvoice) > 0){
                calculate = false;
            }

            if(calculate && promotionValue > 0) {
                if (promotionType < 1) {
                    returnTotal = that.getPercentValue(total, promotionValue).minusValue;
                    hasDiscount = true;
                } else if (promotionType === 1) {
                    if(orderCount !== null && typeof orderCount === "number"){
                        //for per product
                        returnTotal = total - (promotionValue * orderCount);
                    }else {
                        //1 time for all products
                        returnTotal = total - promotionValue;
                    }
                    hasDiscount = true;
                } else {
                    //setup promotion
                }
            }
            if(returnTotal < 0){
                returnTotal = 0;
            }
            return {
                hasDiscount, 
                'total': returnTotal, 
                pValue: promotionValue, 
                pType: promotionType, 
                pCode: promotion.code,
                isrecurring,
                recurringlimit,
                calculate
            };
        },
    },
    mounted() {
        let that = this;
        that.$root.appEvents.on('request_ended.onGetPromotionInfo', function (data) {
            if(Object.prototype.hasOwnProperty.call(data, 'codes')){
                that.promotionCodes = data.codes;
            }
            if(Object.prototype.hasOwnProperty.call(data, 'monthly')){
                that.monthlyPromotions = data.monthly;
            }
            if(Object.prototype.hasOwnProperty.call(data, 'annually')){
                that.annuallyPromotions = data.annually;
            }
        });
    }
}

export { Promotions }
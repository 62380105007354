<template>
    <div class="line-box mb-3" :class="itemContainerCss">
        <div class="line-box-header number-form-header d-flex align-items-center">
            <div class="added-number d-flex align-items-center mr-3" :class="{'theme-text-green':isCheckedInfo, 'text-danger':hasParentError}">
                <i class="fal fa-check theme-text-green card-delete-btn"/>
            </div>
            <span class="font-size-15 font-weight-bold" v-if="isBlockNumber">
              <span class="d-md-inline">0{{ item.formatNumber }}</span> 
              <span class="d-md-inline mx-0 mx-md-2" style="font-weight: lighter;"> t/m</span> 
              <span class="d-md-inline d-block">0{{item.formatEndNumber}}</span>
            </span>
            <span class="font-size-15 font-weight-bold" v-else>0{{ item.formatNumber }}</span>
            <div class="number-action ml-auto">
                <i class="fal fa-times hand card-delete-btn" @click="deleteItem()"/>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TransferNumberItem",
        props:{
            numberIndex:{
                type:[Number, String],
                require: true
            },
            item:{
                type: Object,
                require: true
            }
        },
        data(){
            return {
                startRequest:false,
                sendRequest:false,
                hasParentError:false,
            }
        },
        computed:{
            isCheckedInfo(){
                let that = this;
                let status = true;
                try {
                    ["companyName", "postCode", "homeNumber", "streetName", "city"].forEach(function (key) {
                        if (that.item[key]+"".length < 1 || typeof that.item[key] === "undefined") {
                            status = false;
                        }
                    });
                }catch (e) {
                    status = false;
                }
                return status;
            },
            showForm(){
                if(this.item.isValidPostCode === false){
                    return true;
                }
                return this.sendRequest === true && this.isCheckedInfo !== true && this.item.isValidPostCode === false;
            },
            showFormText(){
                return this.sendRequest === true && this.isCheckedInfo !== true;
            },
            hasError(){
                return this.showFormText && this.item.isValidPostCode !== true;
            },
            itemContainerCss(){
                let cssObj = {};
                if(this.item.tabs){
                    cssObj.active = true;
                }else{
                    cssObj.active = false;
                }
                if(this.hasParentError){
                    cssObj.animate__flash = true;
                    cssObj.animate__animated = true;
                }
                else{
                    cssObj.animate__flash = false;
                    cssObj.animate__animated = false;
                }
                return cssObj;
            },
            isBlockNumber(){
              try{
                  return parseInt(this.item.blockSize, 10) > 1
              }catch (e) {
                return false;
              }
            }
        },
        watch:{
          isCheckedInfo(newValue){
            if(newValue === true){
              this.$emit("applyInfoToOhters", this.numberIndex);
            }
          }
        },
        methods:{
            accordionToggle(){
                this.item.tabs = !this.item.tabs;
            },
            checkPostCode(){
                let that = this;
                if(that.item.postCode.length > 0){
                    that.item.postCode      = that.item.postCode.toUpperCase();
                }
                else{
                    that.item.isValidPostCode = false;
                }
                if(that.item.homeNumber.length > 5){
                    that.item.homeNumber    = that.item.homeNumber.slice(0,5);
                }

                if(that.item.postCode.length > 0 && that.item.homeNumber.length > 0){
                    that.startRequest = true;
                    that.item.isValidPostCode = false;
                    that.sendRequest = false;
                    this.$root.getPostCodeInfo(that.item.postCode, function (receiveObject) {
                        try {
                            that.item.streetName    = receiveObject.tapi_streetname;
                            that.item.city          = receiveObject.tapi_cityname;
                            that.item.region        = receiveObject.tapi_provincename;
                            if(receiveObject.tapi_result === "success"){
                                that.item.isValidPostCode = true;
                            }else{
                                that.item.isValidPostCode = false;
                            }
                        }catch (err) {
                            that.item.streetName    = '';
                            that.item.city          = '';
                            that.item.region        = '';
                            that.item.isValidPostCode = false;
                            that.$root.errorLog(err);
                        }
                        that.sendRequest    = true;
                        that.startRequest   = false;
                    });
                }
            },
            clearPostCode(){
                this.item.postCode = this.item.postCode.replace(/\W/g, '').toUpperCase();
            },
            clearAdditionalNo(){
                this.item.additionalNo = this.item.additionalNo.replace(/\W/g, '').toUpperCase();
            },
            deleteItem(){
                this.$emit("deleteItem", this.numberIndex);
            },
            setError(){
                let that = this;
                this.item.tabs = true;
                this.hasParentError = true;

                setTimeout(function () {
                    that.hasParentError = false;
                },5000);
            }
        }
    }
</script>

<style lang="scss">
.line-box{
  background-color: #FFFFFF;
  border-bottom: 1px solid #EAECEF;
  padding: 17px 20px;
  border-radius: 4px;
}
    .number-form-header{
        .number-owner-data{
            .fa-info-circle{
                font-size: 12px !important;
            }
        }
        * {
          text-decoration: none !important;
        }
    }
    .number-owner-form{
        .form-group{
            label{
                color: #187DC1;
                font-size: 15px;
                font-weight: bold;
            }
            &.post-code-area{
                position: relative;
                .fa-spinner{
                    position: absolute;
                    top: calc(50% + 7px);
                    right: 7px;
                }
            }
        }
    }
</style>
import {Http} from "../classes/Http";

const VzkrRequest = {
    data(){
        return {
            requestUrl:'',
        }
    },
    methods:{
        sendRequest(param, successCallback=function () {}, errorCallback=function () {}){
            let that = this;
            // $.ajax({
            //     data:param,
            //     success:function (response) {
            //         console.error(param);
            //         successCallback(response);
            //     },
            //     error:function (er) {
            //         errorCallback(er);
            //     }
            // });

            return new Promise(function (resolve, reject) {
                Http.post(that.requestUrl+"/funnel.php", function (http) {
                    http.header("Content-Type", "application/x-www-form-urlencoded");
                    http.data(param);
                    http.setOption("redirect", "follow");
                    http.setOption("referrerPolicy", "no-referrer");
                    http.success = function(response){ successCallback(http, response); resolve(http); };
                    http.error = function(error){ errorCallback(http); reject(error) };
                });
            });
        }
    },
    created() {
        if(location.hostname.indexOf("voipzeker.nl") > -1){
            this.requestUrl = location.protocol+"//"+location.hostname;
        }
        if(typeof window.localRequestUrl !== "undefined"){
            this.requestUrl = window.localRequestUrl;
        }
        if(this.requestUrl.indexOf('-dev') > -1){
            this.$root.allowConsole = true;
        }
    },
    mounted(){

    }
};

export { VzkrRequest }
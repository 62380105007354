import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Dialog from "./plugins/dialog";
const VueCookie = require('vue-cookie');
import {VzkrRequest} from "./mixins/VzkrRequest";
import {DataModel} from "./mixins/DataModel";
import {AppLoaders} from "./mixins/AppLoaders";
import {MapManager} from "./mixins/MapManager";
import {AllFilters} from "./mixins/AllFilters";
import {Promotions} from "@/mixins/promotions";

window.eventBus = new Vue();

Object.entries(AllFilters).forEach(function ([name, method]) {
  Vue.filter(name, method);
});

Vue.use(Dialog);
Vue.use(VueCookie);
Vue.config.productionTip = false;

window.App = new Vue({
  router,
  mixins:[VzkrRequest, AppLoaders, MapManager, DataModel, Promotions],
  render: function (h) { return h(App) }
}).$mount('#app');
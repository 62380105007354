import Vue from 'vue'
import VueRouter from 'vue-router';
import Pakketten from '../views/Pakketten.vue';
import MainPage from "../views/MainPage";
import NotFound from "../views/NotFound";

Vue.use(VueRouter);

const routes = [
  {
    path: '/bestellen/:map',
    name: 'mainPage',
    component: Pakketten
  },
  {
    path: '/bestellen/:map/:sub',
    name: 'subPage',
    component: MainPage
  },
  {
    path: '/bestellen/:map/:sub/:act',//not use
    name: 'subPageAct',
    component: Pakketten
  },
  {
    path: '/voip/:map',//'/voip/zakelijke-telefonie-pakketten',
    name: 'singlePackage',
    component: Pakketten,
    meta:{landing:'voip'}
  },
  {
    path: '/',
    name: 'Pakketten',
    component: Pakketten
  },
  {
    path: '*',
    name: 'NotFound',
    component: Pakketten
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router

<template>
    <div class="card-vamo-list" v-if="basketItem.orderCount > 0">
        <div class="row" v-for="(opt,i) in flattenList" :key="'vamo_item_'+i">
          <div class="col-6 col-sm-8 pr-0 mb-1">
            <span class="mr-1">
              <i class="fal fa-times hand mr-1 card-delete-btn" @click="removeProduct(opt, i)" />
            </span>
            <span>{{ subProductFiled(opt, 'name') }}</span>
            <div class="opt-product theme-text-dark font-size-13">
              <div class="price-item" v-if="opt.info.useDijitalSim">
                <span class="icon-point mr-1 ml-3"></span>
                <span>Ik wil een eSIM</span>
              </div>
              <div class="price-item d-flex align-items-center" v-if="opt.info.useTransfer">
                <span class="icon-point mr-1 ml-3"></span>
                <span>Ik wil nummerbehoud : <span class="text-nowrap">{{ opt.info.formatterTransferNumber }}</span></span>
              </div>
            </div>
          </div>
          <div class="col-3 col-sm-2 pr-0 d-flex align-items-center justify-content-end"><span class="text-nowrap">{{ $root.productSetupPrice(opt.productID)|price }}</span></div>
          <div class="col-3 col-sm-2 d-flex align-items-center justify-content-end"><span class="text-nowrap">{{ discountPerPrice(opt)|price }}</span></div>
        </div>
    </div>
</template>

<script>
    import { Abstract_wilkelmand} from "../../mixins/Abstract_wilkelmand";
    import BasketItemHeader from "./BasketItemHeader";
    import dcollect from "dcollect";
    export default {
        name: "VamoBasketList",
        mixins:[Abstract_wilkelmand],
        components:{ BasketItemHeader },
        props:{
            viewHeader:{
                type: Boolean,
                required:false,
                default:true
            }
        },
        data(){
            return {
                flattenList:[]
            }
        },
        computed:{
            headerName(){
                // if(this.basketItem.formType !== "vamo"){
                //     return 'Mobiele telefonie';
                // }
                return 'VaMo (Vast mobiel integratie)';
            }
        },
        methods:{
            allCount(){
                let collect;
                try{
                    collect = new dcollect(Object.values(this.basketItem.options));
                    return collect.query().sum('orderCount');
                }catch (e) {
                    return '';
                }
            },
            subProductFiled(opt, fieldName = 'name'){
                let product = this.$root.products[opt.productID];
                try {
                    if(fieldName === 'price'){
                        return  parseFloat(product.priceList[1]);
                    }else{
                        return product[fieldName];
                    }
                }catch (e) {
                    return '';
                }
            },
            hasDiscount(opt){
                return this.$root.setPromotionsProductPrice("monthly", opt.productID, this.subProductFiled(opt, 'price')).hasDiscount;
            },
            discountPerPrice(opt){
                return this.$root.setPromotionsProductPrice("monthly", opt.productID, this.subProductFiled(opt, 'price')).total;
            },
            removeProduct(opt, fi){
                let that = this;
                this.basketItem.options[opt.productID].info = this.basketItem.options[opt.productID].info.filter(function (item, i) {
                    return i !== opt.key;
                });

                this.basketItem.options[opt.productID].orderCount = this.basketItem.options[opt.productID].info.length;

                if(this.basketItem.options[opt.productID].info.length < 1){
                    delete this.basketItem.options[opt.productID];
                }

                this.basketItem.options = Object.assign({}, this.basketItem.options);

                this.basketItem.orderCount = Object.keys(this.basketItem.options).length;

                if(this.basketItem.orderCount < 1){
                    delete this.$root.basket[this.basketItem.formType];
                }

                this.flattenList = this.flattenList.filter(function (data, key) {
                    return key !== fi;
                });

                this.calculateVamo(this.basketItem);

                that.$root.appEvents.emit('basket-re-calculate');
                that.$root.appEvents.emit('change_basket', that.$root.basket);
                that.flattenList = that.flattenSimProduct(that.basketItem);
            }
        },
        mounted() {
            if(this.viewHeader === false){
              this.collapse = true;
            }
            this.calculateVamo(this.basketItem);
            this.flattenList = this.flattenSimProduct(this.basketItem);
        }
    }
</script>

<style scoped>

</style>
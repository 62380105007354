const EventEmitter = require('events');
const AppLoaders = {
    data(){
        return {
            loading:false,
            mapLoading:false,
            loadedPackage:false,
            readyHardwareProducts:false,
            readyVastOpMobielProducts:false,
            cookieProductLoaded:false,
            loadingText:'',
            cartPageIsDisplayed:false,
            appEvents: new EventEmitter(),
            allowConsole:false,

            winWidth: 0,
            winHeight: 0
        }
    },
    computed:{
        packageLoad(){
            return this.loadedPackage;
        }
    },
    methods:{
        errorLog(){
            if(this.allowConsole !== true){
                return;
            }
            console.error(...arguments);
        },
        infoLog(){
            if(this.allowConsole !== true){
                return;
            }
            console.log(...arguments);
        },
        handleResize: function () {
            this.winWidth = document.documentElement.clientWidth;
            this.winHeight = document.documentElement.clientHeight;
        }
    },
    created(){
        this.handleResize();
    },
    mounted(){
        let that = this;
        that.$root.appEvents.on("map-loaded", function () {
            that.mapLoading = true;
        });
        window.addEventListener("resize", this.handleResize);
    }
};

export { AppLoaders }

<template>
    <div class="map-box-main basket-header mobile-product-item hand row py-4 theme-text-dark" :class="{active:headerValues.collapse}" @click="toggleCollapse()">
        <div class="col-12 col-sm-7 d-flex justify-content-start align-items-center">
            <span class="d-none d-md-block mr-2">{{ headerValues.allCount }} ×</span>
            <i class="fas theme-text-blue font-size-28 mr-2" :class="headerValues.icon"/>
            <span class="font-weight-bold">{{ headerValues.title }}</span>


            <div class="goto-product d-flex justify-content-start align-items-center ml-md-auto ml-4 mr-2">



                <span class="d-none d-md-block hand font-weight-bold theme-text-blue hover-under" @click="returnPage(headerValues.goTo)">Wijzigen</span>
                <i class="fal fa-cog theme-text-blue hand d-block d-md-none" @click="returnPage(headerValues.goTo)" />
            </div>
        </div>
        <div class="col-12 col-sm-5 d-flex justify-content-end align-items-center mt-3 mt-md-0">

            <!--
            <span class="font-weight-bold ml-auto mr-4 badge text-white p-1" style="background-color: #82b636;" v-if="headerValues.basketType === 'flex' || headerValues.basketType === 'basis'">1 mnd gratis</span>
            -->


            <div class="bottom-price d-flex align-items-center justify-content-end mr-5" v-if="hasDiscount">
                <div class="old-price font-weight-bold text-nowrap mr-2">{{ headerValues.basket.oldTotal|price }}</div>
                <div class="new-price font-weight-bold text-nowrap font-size-20">{{ headerValues.total|price }}</div>
            </div>

            <span class="font-size-20 font-weight-bold ml-sm-auto text-nowrap mr-5" v-else>{{ headerValues.total|price }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BasketItemHeader",
        props:{
            headerValues: {
                type:Object,
                required:true
            }
        },
        computed:{
            hasDiscount(){
                try{
                    return this.headerValues.basket.hasDiscount;
                }catch (e) {
                    return false;
                }
            }
        },
        methods:{
            toggleCollapse(){
                // this.headerValues.collapse = !this.headerValues.collapse;
                this.$emit('toggleCollapse');
            },
            returnPage(goto){
              if(goto.indexOf('#') > -1){
                this.$root.gotoPage(goto.split('#')[0], {hash:goto.split('#')[1]});
              }else{
                this.$root.gotoPage(goto);
              }
            }
        }
    }
</script>

<style lang="scss">
    .basket-header{

        &:hover{
            background-color: #f4f4f4;
        }
    }
</style>

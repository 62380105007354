<template>
    <div class="card-number-list">

        <!-- <BasketItemHeader :headerValues="{collapse, allCount:allCountProps(), goTo:'telefoonummers', icon:'fa-light fa-list-ol',  basketType:'newNumber', basket:listItems.newNumber, total:newNumberTotal, title:'Telefoonnummer'}" v-on:toggleCollapse="toggleCollapse" v-if="viewHeader"/>-->
      <div class="row mb-1" v-for="(opt,i) in itemOptions" :key="'phonenummer_item_'+i">
        <div class="col-6 col-sm-8 pr-0 mb-1 text-left">
          <span class="mr-1 mr-md-3">
            <i class="fal fa-times hand card-delete-btn" @click="removeProduct(i)" />
          </span>
          <span>{{ getPhoneNumber(opt, i) }}</span>
        </div>
        <div class="col-3 col-sm-2 pr-0 d-flex align-items-center justify-content-end"><span class="text-nowrap">{{ opt.setupPrice|price }}</span></div>
        <div class="col-3 col-sm-2 d-flex align-items-center justify-content-end"><span class="text-nowrap">{{ opt.price|price }}</span></div>
      </div>
      <input id="delete-focus" type="hidden">
    </div>
</template>

<script>
    import { Abstract_wilkelmand} from "../../mixins/Abstract_wilkelmand";
    import BasketItemHeader from "./BasketItemHeader";
    export default {
        name: "NewNumberBasketList",
        mixins:[Abstract_wilkelmand],
        components:{ BasketItemHeader },
        props:{
          viewHeader:{
            type: Boolean,
            required:false,
            default:true
          }
        },
        data(){
          return {
            itemOptions:[]
          }
        },
        computed:{
          // numberPrice(){
          //   return this.$root.newNumberConf.price;
          // },
          // numberSetupPrice(){
          //   return this.$root.newNumberConf.setupPrice;
          // },
          basketPhoneNumbers(){
            if(Object.prototype.hasOwnProperty.call(this.$root.basket, 'phoneNumbers')){
              return this.$root.basket['phoneNumbers'];
            }
          },
          // itemOptions(){
          //   if(Object.prototype.hasOwnProperty.call(this.$root.basket, 'phoneNumbers')){
          //     return this.$root.basket['phoneNumbers'].options;
          //   }
          //   return {};
          // }
        },
        methods:{
            removeProduct(key){

                if(typeof this.itemOptions[key] !== "undefined"){
                  let numKey  = this.itemOptions[key].areacode+this.itemOptions[key].phone;
                  let id  = this.itemOptions[key].id;

                  this.$root.forNumberRequest(this.basketItem.options[numKey], 'remove', 'new');
                  //delete this.basketItem.options[numKey];
                  delete this.$root.basket['phoneNumbers'].options[numKey];

                  if(Object.keys(this.basketItem.options) < 1){
                    delete this.$root.basket['phoneNumbers'];
                  }

                  this.makeNumberListWithPrice().catch(e=>e);

                  //this.$forceUpdate();
                  this.calculateNewNumber(this.basketItem, true);
                  this.$root.appEvents.emit('basket-re-calculate');
                  this.$root.appEvents.emit('change_basket', this.basket);
                  document.getElementById("delete-focus").focus();
                }

            },
            getPhoneNumber(opt, k){
                  if(opt.hasOwnProperty('phoneformat')){
                      return opt.phoneformat;
                  }
                  else if(opt.hasOwnProperty('number_start_full')){
                      this.$root.reEditPhoneNumber(k);
                      return opt.number_start_full.replace(/\+/g, ' ');
                  }
                  if(opt.hasOwnProperty('phone')){
                      return opt.areacode+""+opt.phone;
                  }else{
                      this.$root.reEditPhoneNumber(k);
                      return opt.areacode+""+opt.number_start;
                  }
            },
            lastCookieChanged(manuel){
                if(manuel !== true){
                    this.calculateNewNumber(this.basketItem, true);
                }
            },
            allCountProps(){
                try{
                    return Object.keys(this.basketItem.options).length;
                }catch (e) {
                    return '';
                }
            },

            async makeNumberListWithPrice(){
              this.itemOptions = [];

              if(Object.prototype.hasOwnProperty.call(this.$root.basket, 'phoneNumbers')){

                for(let [k,n] of Object.entries(this.$root.basket['phoneNumbers'].options) ){
                  let numberProduct = await this.$root.numberProductData(n.areacode, false);
                  this.itemOptions.push(
                      Object.assign(n, {
                        price:numberProduct.price,
                        setupPrice: numberProduct.setupPrice
                      })
                  );
                }
              }else{
                this.itemOptions = [];
              }
            }
        },
        async mounted() {
            if(this.viewHeader === false){
              this.collapse = true;
            }
            await this.calculateNewNumber(this.basketItem);
            this.$root.appEvents.on('newNumber-re-calculate', this.lastCookieChanged);

            this.makeNumberListWithPrice().catch(e=>e);

        },
        destroyed() {
            this.$root.appEvents.removeListener('newNumber-re-calculate', this.lastCookieChanged);
        }
    }
</script>

<style scoped>

</style>

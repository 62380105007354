<template>
    <div class="row">

        <!--<BasketItemHeader :headerValues="{collapse, allCount, goTo:'gebruikers', icon:'fa-user',  basketType:'flex', basket:listItems.flex, total:flexTotal, title:'Unite phone gebruiker'}" v-on:toggleCollapse="toggleCollapse" v-if="viewHeader"/>-->

          <div class="col-6 col-sm-8 pr-0 mb-2 pb-1 text-nowrap">
              <span class="mr-1">{{ allCount }}x</span>
              <span class="font-size-15 font-size-xs-11">Unite phone gebruiker</span>
          </div>
          <div class="col-3 col-sm-2 pr-0 text-right"><span class="text-nowrap">{{ setupPrice|price }}</span></div>
          <div class="col-3 col-sm-2 text-right"><span class="text-nowrap">{{ allPrice|price }}</span></div>



          <div class="col-6 col-sm-8 pr-0 mb-1">
            <div class="d-flex align-items-center">
              <!--<span class="icon-point mr-1 ml-3"></span>-->
              <i class="fal fa-check theme-text-green card-delete-btn mr-1 mr-md-3"></i>
              <span class="font-size-15 font-size-xs-11">Desktop</span>
            </div>
          </div>
          <div class="col-3 col-sm-2 pr-0 d-flex align-items-center justify-content-end"><span class="text-nowrap">{{ 0|price }}</span></div>
          <div class="col-3 col-sm-2 d-flex align-items-center justify-content-end"><span class="text-nowrap">{{ 0|price }}</span></div>


          <div class="clearfix"></div>

          <div class="col-6 col-sm-8 pr-0 mb-1">
            <div class="d-flex align-items-center">
              <!--<span class="icon-point mr-1 ml-3"></span>-->
              <i class="fal fa-check theme-text-green card-delete-btn mr-1 mr-md-3"></i>
              <span class="font-size-15 font-size-xs-11">Mobiele app</span>
            </div>
          </div>
          <div class="col-3 col-sm-2 pr-0 d-flex align-items-center justify-content-end"><span class="text-nowrap">{{ mobilSetupPrice|price }}</span></div>
          <div class="col-3 col-sm-2 d-flex align-items-center justify-content-end"><span class="text-nowrap">{{ mobilDiscountPrice|price }}</span></div>

          <div class="clearfix"></div>

          <div class="col-6 col-sm-8 pr-0 mb-1">
            <div class="d-flex align-items-center">
              <!--<span class="icon-point mr-1 ml-3"></span>-->
              <i class="fal fa-check theme-text-green card-delete-btn mr-1 mr-md-3"></i>
              <span class="font-size-15 font-size-xs-11">Vast</span>
            </div>
          </div>
          <div class="col-3 col-sm-2 pr-0 d-flex align-items-center justify-content-end"><span class="text-nowrap">{{ vastSetupPrice|price }}</span></div>
          <div class="col-3 col-sm-2 d-flex align-items-center justify-content-end"><span class="text-nowrap">{{ vastDiscountPrice|price }}</span></div>


        <!--
        <div class="product-list mx-2 mx-md-5 my-3" v-if="collapse">
            <div class="row border-bottom py-2">
              <div class="col-12 col-sm-6">
                  <i class="fa fa-check mr-2 theme-text-blue"/>
                  <span>Bellen via desktop</span>
              </div>
              <div class="col-12 col-sm-6">
                  <div class="d-flex align-items-center justify-content-end" v-if="hasMobilDiscount">
                    <div class="old-price text-nowrap font-size-12 mr-2">{{ mobilPrice|price }}</div>
                    <div class="new-price text-nowrap font-weight-bold">{{ mobilDiscountPrice|price }}</div>
                  </div>
                  <div class="d-flex align-items-center justify-content-end" v-else>
                    <div class="new-price text-nowrap font-weight-bold mr-2">{{ mobilPrice|price }}</div>
                  </div>
              </div>
            </div>

            <div class="row border-bottom py-2">
                <div class="col-12 col-sm-6">
                  <i class="fa fa-check mr-2 theme-text-blue"/>
                  <span>Bellen via mobiel (app)</span>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="d-flex align-items-center justify-content-end" v-if="hasMobilDiscount">
                    <div class="old-price text-nowrap font-size-12 mr-2">{{ mobilPrice|price }}</div>
                    <div class="new-price text-nowrap font-weight-bold">{{ mobilDiscountPrice|price }}</div>
                  </div>
                  <div class="d-flex align-items-center justify-content-end" v-else>
                    <div class="new-price text-nowrap font-weight-bold mr-2">{{ mobilPrice|price }}</div>
                  </div>
                </div>
            </div>
            <div class="row py-2">
                <div class="col-12 col-sm-6">
                  <i class="fa fa-check mr-2 theme-text-blue"/>
                  <span>Bellen via vaste telefoon</span>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="d-flex align-items-center justify-content-end" v-if="hasMobilDiscount">
                    <div class="old-price text-nowrap font-size-12 mr-2">{{ mobilPrice|price }}</div>
                    <div class="new-price text-nowrap font-weight-bold">{{ mobilDiscountPrice|price }}</div>
                  </div>
                  <div class="d-flex align-items-center justify-content-end" v-else>
                    <div class="new-price text-nowrap font-weight-bold mr-2">{{ mobilPrice|price }}</div>
                  </div>
                </div>
            </div>
        </div>
        -->
    </div>
</template>

<script>
    import { Abstract_wilkelmand} from "../../mixins/Abstract_wilkelmand";
    import BasketItemHeader from "./BasketItemHeader";
    export default {
        name: "FlexBasketList",
        mixins:[Abstract_wilkelmand],
        components:{ BasketItemHeader },
        props:{
            viewHeader:{
              type: Boolean,
              required:false,
              default:true
            }
        },
        computed:{
            flexProduct(){
                return this.$root.products[this.basketItem.id];
            },
            flexPerPrice(){
                let that = this;
                let perAmount;
                let numKys = Object.keys(this.flexProduct.priceList).map(function (i) { return i * 1; });//as numeric

                //birim fiyatini bul
                numKys.forEach(function (pIndex) {
                    if(that.basketItem.orderCount >= pIndex){
                        perAmount = that.flexProduct.priceList[pIndex] / pIndex;
                    }
                });

                return perAmount;
            },
            allPrice(){
              try{
                return this.totalItem.total;
              }catch (e) {
                return 0;
              }
            },
            setupPrice(){
              try{
                return this.totalItem.setupTotal;
              }catch (e) {
                return 0;
              }
            },
            hasDiscount(){
                return this.$root.setPromotionsProductPrice("monthly", this.basketItem.id, this.flexPerPrice).hasDiscount;
            },
            allCount(){
                try{
                    return this.basketItem.orderCount;
                }catch (e) {
                    return 1;
                }
            },
            mobilPrice(){
              try{
                  //return (this.basketItem.options[0].mobilePrice * this.allCount);
                return this.$root.calculateProductPrice(this.$root.flexSubProduct.id, this.allCount);
              }catch (e) {
                  return 0;
              }
            },
            mobilSetupPrice(){
              try{
                return this.$root.productSetupPrice(this.$root.flexSubProduct.id, this.allCount);
              }catch (e) {
                return 0;
              }
            },
            hasMobilDiscount(){
                try{
                  return this.$root.setPromotionsProductPrice("monthly", this.$root.flexSubProduct.id, this.mobilPrice).hasDiscount;
                }catch (e) {
                  return false;
                }
            },
            mobilDiscountPrice(){
              try{
                return this.$root.setPromotionsProductPrice("monthly", this.$root.flexSubProduct.id, this.mobilPrice, this.allCount).total;
              }catch (e) {
                return this.mobilPrice;
              }
            },

            vastTelPrice(){
              try{
                return this.$root.calculateProductPrice(this.$root.flexVastProduct.id, this.allCount);
              }catch (e) {
                return 0;
              }
            },
            vastSetupPrice(){
              try{
                return this.$root.productSetupPrice(this.$root.flexVastProduct.id, this.allCount);
              }catch (e) {
                return 0;
              }
            },
            vastDiscountPrice(){
              try{
                return this.$root.setPromotionsProductPrice("monthly", this.$root.flexVastProduct.id, this.vastTelPrice, this.allCount).total;
              }catch (e) {
                return this.vastTelPrice;
              }
            }
        },
        methods:{
            perPrice(key){
                let that = this;
                let opt     = this.basketItem.options[key];
                let total   = this.flexPerPrice;
                /*
                if(opt.usesim){
                    //total += parseFloat(opt.vastPrice);
                    total += this.getProductsPrice(this.flexProduct.relation, opt.selectInternetPackage);
                }
                if(opt.vast1){
                    total += parseFloat(opt.vastPrice);
                }
                if(opt.vast2){
                    total += parseFloat(opt.vastPrice);
                }
                */

                /*
                if(that.selectedProperty(key) === 3){
                    total += (parseFloat(opt.mobilePrice) * 2);
                }else if(that.selectedProperty(key) === 2){
                    total += parseFloat(opt.mobilePrice);
                }
                */
                return total;
            },
            selectedProperty(key, returnType = 'count'){
                let properties = [];
                let icons   = this.$root.productTypes.flex.propsIcons;
                let iconView = '';
                let opt     = this.basketItem.options[key];
                if(opt.desktop){
                    properties.push("desktop");
                    iconView += '<i class="'+icons['desktop']+' ml-1" title="Desktop"></i> ';
                }
                if(opt.mobiel){
                    properties.push("mobiel");
                    iconView += '<i class="'+icons['mobiel']+' ml-1" title="Mobiel"></i> ';
                }
                if(opt.vast1){
                    properties.push("vast");
                    iconView += '<i class="'+icons['vast']+' ml-1" title="Vast"></i> ';
                }
                if(returnType === 'names' && properties.length > 0){
                    return properties.join(', ');
                }
                else if(returnType === 'array' && properties.length > 0){
                    return properties;
                }
                else if(returnType === 'icon' && properties.length > 0){
                    return iconView;
                }
                return properties.length;
            },
            discountPerPrice(key){
                let allTotal = this.perPrice(key);
                let promotion = this.$root.setPromotionsProductPrice("monthly", this.basketItem.id, this.flexPerPrice);
                if(promotion.hasDiscount){
                    return (allTotal - this.flexPerPrice) + promotion.total;
                }
                return allTotal;
            },
            vastPrice(optKey, piece){
                let opt     = this.basketItem.options[optKey];
                let total   = parseFloat(opt.vastPrice);

                return total * piece;
            },
            simPrice(optKey){
                let opt     = this.basketItem.options[optKey];
                //let total   = parseFloat(opt.vastPrice);
                //total += this.getProductsPrice(this.flexProduct.relation, opt.selectInternetPackage);
                //return total;
                return this.getProductsPrice(this.flexProduct.relation, opt.selectInternetPackage);
            },
            simProductGetField(optKey, getFieldName = 'name'){
                let opt     = this.basketItem.options[optKey];
                try{
                    return this.flexProduct.relation[opt.selectInternetPackage][getFieldName];
                }catch (e) {
                    return '';
                }
            },
            removeProduct(optKey){
                /** basketItem prop olarak geldiği için reaktif bu yüzden set işlemlerine gerek yok sadece çıkarmak yeterli **/
                this.basketItem.options = this.basketItem.options.filter(function (opt,k) {
                    return k !== optKey;
                });
                this.basketItem.orderCount = this.basketItem.options.length;

                if(this.basketItem.orderCount < 1){
                    delete this.$root.basket[this.basketItem.id];
                }

                this.calculateFlex(this.basketItem);
                this.$root.appEvents.emit('basket-re-calculate');
                this.$root.appEvents.emit('change_basket', this.basket);
            }
        },
        mounted() {
            if(this.viewHeader === false){
              this.collapse = true;
            }
            this.calculateFlex(this.basketItem);
        }
    }
</script>

<style scoped>

</style>